import Api from '../../../common/services/api';
import { SOCIAL_DELETE_MESSAGE } from './index';

export default (messageId, scheduled = false) => async (dispatch) => {
  Api.delete(`/v1/messages/${messageId}`);

  return dispatch({
    type: SOCIAL_DELETE_MESSAGE,
    messageId,
    scheduled,
  });
};
