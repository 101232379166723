import Api from '../../../common/services/api';

export const SOCIAL_RECEIVE_MESSAGE = 'social/RECEIVE_MESSAGE';

export default (messageId) => async (dispatch) => {
  const { data, meta } = await Api.get(`/v2/messages/${messageId}`);

  dispatch({
    type: SOCIAL_RECEIVE_MESSAGE,
    message: data,
    related: meta ? meta.related : null,
  });
};
