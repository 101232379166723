import * as R from 'ramda';
import Api from '../../../common/services/api';

export const CLEAR_NOTIFICATION = 'core/CLEAR_NOTIFICATION';

/**
 * Mark notifications for a specific type and id as read
 * @param {string} sourceType - Type of the source
 * @param {string} sourceId - Id of the source
 * @method
 * @return {Promise}
 */
export default (sourceType, sourceId) => (dispatch, getState) => {
  const { organisation: { selected }, notifications: { items } } = getState();

  const itemsToMarkAsSeen = R.filter(R.allPass([
    R.propEq('source_type', sourceType),
    R.propEq('source_id', sourceId),
    R.propEq('seen', false),
  ]), items || []);

  const payload = { source_type: sourceType, source_id: sourceId };
  Api.post(`/v2/organisations/${selected.id}/activities/seen`, payload);

  dispatch({
    type: CLEAR_NOTIFICATION,
    sourceType,
    sourceId,
    notificationsCount: R.length(itemsToMarkAsSeen),
  });
};
