import React, { Component } from 'react';
import { connect } from 'react-redux';
import { parse } from 'qs';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import Container from '../../../../common/components/container';
import Spinner from '../../../../common/components/spinner';
import MessageItem from '../../components/feed-item/message';
import * as userSelector from '../../../core/selectors/logged-user';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import * as messagesReducer from '../../reducers/messages';
import { ESourceTypes } from '../../../../common/definitions';

require('./styles.scss');

class MessageDetailContainer extends Component {
  constructor(props) {
    super(props);

    this.handleHighFive = this.handleHighFive.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  async componentDidMount() {
    const { match: { params } } = this.props;

    await this.props.fetchMessage(params.messageId)
      .then(() => this.props.clearNotification(ESourceTypes.MESSAGE, params.messageId));
  }

  componentDidUpdate(prevProps) {
    const { match: { params }, fetchMessage, clearNotification } = this.props;
    const prevQuery = parse(prevProps.location.search, { ignoreQueryPrefix: true });
    const query = parse(this.props.location.search, { ignoreQueryPrefix: true });

    if (prevProps.match.params.messageId !== params.messageId) {
      fetchMessage(params.messageId);
      clearNotification(ESourceTypes.MESSAGE, params.messageId);
    }

    if (prevQuery.aid !== query.aid) {
      fetchMessage(params.messageId);
    }
  }

  static props;

  handleHighFive(messageId) {
    return this.props.highFiveMessage(messageId);
  }

  async handleDelete(messageId) {
    const { history, networkId, deleteMessage } = this.props;

    await deleteMessage(messageId);

    return history.push(networkId ? `/networks/${networkId}` : '/messages');
  }

  async handleUpdate(messageId, values) {
    await this.props.updateMessage(messageId, values);
  }

  render() {
    const {
      organisation, loggedUser, message, deleteComment, getComments, onHighFiveComment,
    } = this.props;

    const content = !message ? <Spinner size="large" centered /> : (
      <MessageItem
        showComments
        enableOrganisationActions={false}
        item={message}
        organisation={organisation}
        loggedUser={loggedUser}
        onHighFive={this.handleHighFive}
        onDelete={this.handleDelete}
        onDeleteComment={deleteComment}
        getComments={getComments}
        onUpdate={this.handleUpdate}
        onHighFiveComment={onHighFiveComment}
      />
    );

    return (
      <Container name="Social">
        <Container.Content horizontal>
          <Container.SideBar />
          <Container.Column>
            {content}
          </Container.Column>
          <Container.SideBar />
        </Container.Content>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  fetchMessage: require('../../actions/fetch-message').default,
  deleteMessage: require('../../actions/delete-message').default,
  updateMessage: require('../../actions/update-message').default,
  highFiveMessage: require('../../actions/highfive-message').default,
  deleteComment: require('../../actions/delete-comment').default,
  getComments: require('../../actions/fetch-comments').default,
  clearNotification: require('../../../core/actions/clear-notification').default,
  onHighFiveComment: require('../../actions').highFiveComment,
};

const mapStateToProps = (state, props) => ({
  organisation: organisationSelector.selected(state),
  loggedUser: userSelector.selected(state),
  message: messagesReducer.findById(state, props.match.params.messageId),
  networkId: state.network.selected,
});

export default connect(mapStateToProps, mapDispatchToProps)(pageWrapper(EEventNames.VIEWED_MESSAGE_DETAIL)(MessageDetailContainer));
